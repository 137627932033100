/** @jsx jsx */
import { jsx, useColorMode, useThemeUI } from 'theme-ui';
import { graphql } from 'gatsby';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { ProgramDetailLayout } from '../layouts/ProgramDetailLayout';
import { Box, Button, Flex, Link } from 'rebass';
import { H1, H2, H3, H4, Text } from '../components/Text';
import sponsors from '../components/Sponsors';
import { Program } from '../data/program/program';
import { TimesStack } from './ProgramDay';
import { Container } from '../components/Container';
import { useLocalStorateTzOffset } from '../components/TimezonePicker';
import { useTranslation } from '../i18n/TranslationContext';
import { TimeZoneSwitcher } from '../components/TimeZoneSwitcher';
import { LocalizedLink } from '../components/LocalizedLink';

interface ProgramDetailsProps {
  pageContext: {
    program: Program;
  };
  data: any;
}

interface SpeakerDetailsProps {
  speaker: {
    name: string;
    twitter: string;
    srcSet: string;
    fct: string;
    bio: string;
  };
}

const SpeakerDetails: React.FC<SpeakerDetailsProps> = ({
  speaker: { name, twitter, srcSet, fct, bio },
}) => {
  return (
    <Box
      sx={{
        width: ['100%', '100%', 'auto'],
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '134px auto',
          gridTemplateRows: 'auto auto',
          gap: 2,
        }}
      >
        <Flex sx={{ gridColumn: 'span 2' }}>
          <img
            srcSet={srcSet}
            sx={{ width: '118px', height: '118px', mr: 3, mb: 3 }}
          />
          <H2 sx={{ wordBreak: 'break-word' }}>{name}</H2>
        </Flex>

        {(twitter && (
          <a href={twitter} sx={{ variant: 'variants.link' }} target="_blank">
            {`@${twitter.substring(twitter.lastIndexOf('/') + 1)}`}{' '}
          </a>
        )) || <span>&nbsp;</span>}
        <Text sx={{ fontWeight: 'bold' }}>{fct}</Text>
      </Box>
      <ReactMarkdown
        source={bio}
        sx={{
          mt: 3,
          variant: 'text.body',
          a: { variant: 'variants.link' },
          p: { variant: 'text.body', mb: 2 },
        }}
      />
    </Box>
  );
};

type SessionTimeProps = {
  program: Program;
};

const SessionTime: React.FC<SessionTimeProps> = ({ program }) => {
  const { tzOffset, changeTimeZones } = useLocalStorateTzOffset();
  const t = useTranslation();

  const startDates = [
    program.start_date_1,
    program.start_date_2,
    program.start_date_3,
  ];

  const localStartMoments = startDates
    .filter(d => d !== '')
    .map(d => moment(d + '-05:00').utcOffset(tzOffset as number));

  const localEndMoments = localStartMoments.map(lsm =>
    lsm.clone().add(parseInt(program.duration), 'minutes'),
  );

  const now = moment();

  const isHappeningNow = localStartMoments.some(
    (lsm, i) =>
      now.isAfter(localStartMoments[i]) && now.isBefore(localEndMoments[i]),
  );

  const enDayLabels = ['Workshops', 'Day 1', 'Day 2', 'Day 3'];

  return (
    <Box>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          alignItems: ['flex-start', 'flex-start', 'center'],
        }}
      >
        <LocalizedLink
          to={`/program/day_${program.day}`}
          sx={{ variant: 'variants.link', mb: [3, 3, 0] }}
        >
          ← Back to day {enDayLabels[parseInt(program.day)]} schedule
        </LocalizedLink>
        <TimeZoneSwitcher
          tzOffset={tzOffset}
          changeTimeZones={changeTimeZones}
        />
      </Flex>
      <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
        <Box sx={{ width: ['100%', '100%', '75%'] }}>
          <H4 sx={{ textTransform: 'capitalize' }}>{program.kind}</H4>
          <H1>{program.title}</H1>
          <H4>
            {program.speaker_1_name}
            {program.speaker_2_name && `, ${program.speaker_2_name}`}
          </H4>
        </Box>
        <Flex sx={{ width: ['100%', '100%', '25%'], flexDirection: 'column' }}>
          <TimesStack times={localStartMoments} color={'text'} />
          <Text sx={{ fontWeight: '700' }}>{program.format}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

const ProgramDetails: React.FC<ProgramDetailsProps> = ({
  pageContext: { program, ...rest },
  data,
}) => {
  const { theme } = useThemeUI();
  const [colorMode, setColorMode] = useColorMode();

  const themeDarkerColor =
    (colorMode === 'light' ? theme?.colors?.text : theme?.colors?.background) ||
    '#00adef';

  const vimeoColor = themeDarkerColor.substring(1).toLowerCase() || '00adef';

  const numberOfSpeakers = program.speaker_2_name !== '' ? 2 : 1;

  const SponsorLogo =
    program.sponsored === 'TRUE' && sponsors[program.sponsored_by_logo];

  return (
    <ProgramDetailLayout>
      {({ tzOffset }) => (
        <Container>
          <SessionTime program={program} />
          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
              justifyContent: 'space-between',
            }}
          >
            <H4 sx={{ flex: 3, pr: [0, 0, 4] }}>{program.resume}</H4>
            {program.sponsored === 'TRUE' && (
              <Flex
                sx={{
                  flex: 1,
                  width: ['100%', '100%', '225px'],
                  flexDirection: 'column',
                  mt: 3,
                }}
              >
                <span
                  sx={{ fontFamily: 'Vectrex', fontWeight: 700, fontSize: 3 }}
                >
                  Sponsored by
                </span>
                <Link
                  href={program.sponsored_by_link}
                  title={program.sponsored_by}
                  target="_blank"
                >
                  <SponsorLogo sx={{ fill: 'text' }} />
                </Link>
              </Flex>
            )}
          </Flex>
          {program.vimeo && (
            <Box>
              <div sx={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src={`https://player.vimeo.com/video/${program.vimeo}?color=${vimeoColor}&byline=0&portrait=0`}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Box>
          )}
          <Box
            sx={{
              borderTop: 'solid 1px',
              borderColor: 'text',
              my: [4, 4, 5],
            }}
          />
          <Box sx={{ mb: 5 }}>
            <H3 sx={{ mb: 4 }}>Description</H3>
            <ReactMarkdown
              source={program.description}
              sx={{
                variant: 'text.body',
                columnCount: [1, 1, 2],
                columnGap: 3,
                li: { listStyle: 'disc', ml: 3 },
                strong: { fontWeight: 700 },
              }}
            />
          </Box>
          {(program.target_audience || program.outline) && (
            <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
              <Box sx={{ width: ['100%', '100%', '50%'] }}>
                <H3 sx={{ mb: 4 }}>Who is it for?</H3>
                <ReactMarkdown
                  source={program.target_audience}
                  sx={{ variant: 'text.body' }}
                />
              </Box>
              <Box sx={{ width: ['100%', '100%', '50%'] }}>
                <H3 sx={{ mb: 4 }}>Outline</H3>
                <ReactMarkdown
                  source={program.outline}
                  sx={{ variant: 'text.body' }}
                />
              </Box>
            </Flex>
          )}
          <Box sx={{ mb: 5 }}>
            <H3 sx={{ mb: 4 }}>
              Meet the speaker{numberOfSpeakers === 2 ? 's' : ''}
            </H3>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: ['auto', 'auto', '50% 50%'],
                gap: [5, 4, 3],
              }}
            >
              <SpeakerDetails
                speaker={{
                  name: program.speaker_1_name,
                  twitter: program.speaker_1_twitter,
                  srcSet: data?.speaker1Image?.childImageSharp?.fluid?.srcSet,
                  fct: program.speaker_1_function,
                  bio: program.speaker_1_bio,
                }}
              />
              {numberOfSpeakers === 2 && (
                <SpeakerDetails
                  speaker={{
                    name: program.speaker_2_name,
                    twitter: program.speaker_2_twitter,
                    srcSet: data?.speaker2Image?.childImageSharp?.fluid?.srcSet,
                    fct: program.speaker_2_function,
                    bio: program.speaker_2_bio,
                  }}
                />
              )}
            </Box>
          </Box>
        </Container>
      )}
    </ProgramDetailLayout>
  );
};

export default ProgramDetails;
export const query = graphql`
  query findSpeakerImage($speaker1Photo: String, $speaker2Photo: String) {
    speaker1Image: file(relativePath: { eq: $speaker1Photo }) {
      childImageSharp {
        fluid {
          srcSet
          src
          aspectRatio
          sizes
        }
      }
    }
    speaker2Image: file(relativePath: { eq: $speaker2Photo }) {
      childImageSharp {
        fluid {
          srcSet
          src
          aspectRatio
          sizes
        }
      }
    }
    partnerImages: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`;
export { Program };
